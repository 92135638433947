import { gql } from "@apollo/client";

export const GET_USER_DETAILS = gql`
  query GetUserDetails {
    currentUser {
      email
      firstName
      lastName
      dateOfBirth
      mobileNumber
      college
      dateOfBirth
      pensilToken
      location {
        id
        name
        parent {
          name
        }
      }
      profilePicture {
        filename
        url
      }
      activeCart {
        uuid
      }
      getChargebeeId
      getSubscriptionDetails
      isUserSubscribed
    }
  }
`;
